<template>
  <div style="padding-right: 3.4em">
    <!-- <v-snackbar v-model="showsnackbar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{msg}}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color:white;">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar> -->

    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title>Add Quote </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    
                    <v-col cols="12" sm="3" md="2">
                    <v-autocomplete
                        v-model="Company"
                        :items="listItems"
                        item-text="companyName"
                        item-value="_id"
                        dense
                        color="black"
                        label="Company"
                        outlined
                        :search-input.sync="search"
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        ref="contactPerson"
                        color="black"
                        label="Contact"
                        outlined
                        dense
                        v-model="customer.contactPerson"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="email"
                        color="black"
                        label="Email ID"
                        :rules="emailRules"
                        outlined
                        dense
                        v-model="customer.email"
                      ></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        ref="phone"
                        color="black"
                        label="Phone Number"
                        outlined
                        :rules="phoneRules"
                        dense
                        v-model="phoneno"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        ref="fax"
                        color="black"
                        label="Fax"
                        outlined
                        dense
                        v-model="customer.fax"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Sales Rep."
                        outlined
                        dense
                        disabled
                        v-model="agentCode"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <!-- <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Billing Address</span>
                  </v-row> -->

                  

                  <!-- <v-row class="ml-1 mt-4" dense style="width: 100% !important">
                    <span style="font-size: 13px">Shipping Address</span>
                  </v-row> -->

                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Reference"
                        outlined
                        dense
                        v-model="customer.shipAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                     <v-select
                        :items="qtTypes"
                        ref="type"
                        color="black"
                        label="Type"
                        outlined
                        item-text="type"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                    </v-col>

                    <v-col md="3">
                      <v-select
                        :items="qtStock"
                        ref="type"
                        color="black"
                        label="Stock"
                        outlined
                        item-text="stock"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Shape"
                        outlined
                        dense
                        v-model="customer.shipZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Width"
                        outlined
                        dense
                        v-model="customer.address"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Length"
                        outlined
                        dense
                        v-model="customer.city"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                       <v-text-field
                        ref="companyName"
                        color="black"
                        label="Colors"
                        outlined
                        dense
                        v-model="customer.color"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Copies"
                        outlined
                        dense
                        v-model="customer.zip"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                    <v-select
                        :items="qtOptions"
                        ref="type"
                        color="black"
                        label="Options"
                        multiple
                        outlined
                        item-text="options"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                      </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <!-- <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="190px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="customer.initialDate"
                            label="Initial"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.initialDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="customer.lastDate"
                            label="Final"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.lastDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="customer.birthDate"
                            label="DOB"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.birthDate"
                          @input="menu3 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col> -->
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Plate Charge"
                        outlined
                        dense
                        v-model="customer.nature"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Art Work"
                        outlined
                        dense
                        v-model="customer.nature"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                    <v-text-field
                        ref="companyName"
                        color="black"
                        label="Copy Change"
                        outlined
                        dense
                        v-model="customer.nature"
                      ></v-text-field>
                      </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Color Change"
                        outlined
                        dense
                        disabled
                        v-model="agentCode"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Color Match"
                        outlined
                        dense
                        v-model="customer.means"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Die Charge"
                        outlined
                        dense
                        v-model="customer.rating"
                      ></v-text-field>
                    </v-col>

                    <v-col md="3">
                      <v-select
                        :items="qtQuantity"
                        ref="terms"
                        color="black"
                        label="Select Qty(k=1000)"
                        outlined
                        item-text="name"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                       
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                       
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                       
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        color="black"
                        label="Price"
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                    
                     <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                       
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                     <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                       
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                     <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                       
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                      <v-col md="4">
                      <v-text-field
                        disabled
                        color="black"
                        label="Upload Label (max 3 files)"
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>

                   
                    <v-col md="8">
                       <v-file-input
                        v-model="atfiles"
                        @change="getFiles"
                        color="black"
                        
                        ref="files"
                        dense
                        label="File input"
                        multiple
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                       
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 14px"><b>Notes</b></span>
                  </v-row>

                  <v-row dense>
                    <v-col md="2">
                      <v-row dense>
                        <v-col md="12">
                          <v-text-field
                            ref="Agent"
                            color="black"
                            label="Agent"
                            dense
                            disabled
                            outlined
                            v-model="agentCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="10">
                      <v-row dense v-for="(item, i) in notes" :key="i">
                        <v-col md="10">
                          <v-text-field
                            ref="Notes"
                            color="black"
                            label="Note"
                            dense
                            outlined
                            v-model="item.note"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i > 0"
                            class="mx-2"
                            depressed
                            fab
                            dark
                            small
                            color="error"
                            @click="removee(i)"
                          >
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i == notes.length - 1"
                            fab
                            depressed
                            small
                            color="primary"
                            @click="update"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-4" outlined>
                <v-card-title>
                  <v-layout wrap justify-center>
                    <v-flex xs12 md12 text-center>
                      <v-btn
                        dark
                        color="primary"
                        depressed
                        class="text-capitalize"
                        @click="validate()"
                        :ripple="false"
                      >
                        Submit
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      style="position: fixed; padding-top: 4%"
      right
      width="55"
    >
      <v-list nav dense>
        <!-- <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <v-icon color="primary">mdi-file-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item> -->
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <v-icon color="primary">mdi-file-pdf-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link to="/addCustomer" title="Add">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
//import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      search: "",
      Company:null,
      listItems: [],
      valid: true,
      companyRules: [
        (v) => !!v || "Company Name is required",
        (v) =>
          (v && v.length <= 50) ||
          "Company Name must be less than 50 characters",
      ],
      phoneRules: [(v) => !!v || "Phone is required"],
      qtyRules: [(v) => v < 0 || "Number is expected "],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      cutomerId: null,
      cutmId: null,
      customer: {
        companyName: null,
        contactPerson: null,
        email: null,
        nature: null,
        address: null,
        city: null,
        stateId: null,
        countryId: null,
        zip: null,
        phone: null,
        fax: null,
        shipAddress: null,
        shipCity: null,
        shipStateId: null,
        shipCountryId: null,
        shipZip: null,
        birthDate: null,
        initialDate: new Date().toISOString().substr(0, 10),
        lastDate: null,
        termId: null,
        age: null,
        agentCode: null,
        ytd: null,
        means: null,
        rating: null,
        accountNo: null,
        status: "Active",
        description: null,
        
      },
      phoneno: null,
      notes: [
        {
          note: "",
        },
      ],
      agentCode: localStorage.getItem("agentCode"),
      showsnackbar: false,
      msg: null,
      ServerError: false,
      validateInput: null,
      timeout: 5000,
      appLoading: false,
      msg1: null,
      userData: [],
      allStatus: [],
      terms: [],
      states: [],
      qtTypes:[],
      qtStock:[],
      qtOptions:[],
      qtQuantity:[],

      menu1: false,
      menu3: false,
      menu2: false,
    };
  },

  beforeMount() {
    // this.stateList();
    this.quoteType();
    this.quoteStock();
    this.quoteOptions();
    this.quoteQunatity();

    // this.getId();
  },

  watch: {
      search(val) {
      this.getList(val);
    },
    Company() {
     
      this.getrecentList();
    },
    phoneno() {
      //  console.log("here")
      var str = this.phoneno;
      var ind;
      if (str.length == 3) {
        ind = str.substring(0, 3);
        this.phoneno = ind + "-";
      }
      if (str.length == 8) {
        ind = str.substring(0, 7);
        this.phoneno = ind + "-";
      }
    },
  },
  methods: {
       getList(companyname) {
      this.isLoading = true;
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/customer/companyName/search",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          limit: 20,
          page: 1,
          companyName: companyname,
        },
      })
        .then((response) => {
          this.isLoading = false;
          if (response.data.status) {
            // console.log(response.data.data)
            this.listItems = response.data.data;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    getrecentList() {
       axios({
                method: "POST",
                url: "/customer/view/" + this.Company,
                headers: {
                  token: localStorage.getItem("token"),
                },
              })
                .then((response) => {
                  this.appLoading = false;
                  if (response.data.status) {
                    this.customerDtls = response.data.data;
                    this.customer.contactPerson = this.customerDtls.contactPerson;
                     this.customer.address = this.customerDtls.address;
                    this.customer.city = this.customerDtls.city;
                    this.customer.stateId = this.customerDtls.stateId;
                    this.customer.fax = this.customerDtls.fax;
                    this.customer.email = this.customerDtls.email;
                    this.phoneno = this.customerDtls.phone;
                  }
                })
                .catch((err) => {
                  this.ServerError = true;
                  console.log(err);
                });
            
        
    },
    validate() {
      if (this.$refs.form.validate()) this.addCustomer();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getId() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/available/customerid",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.cutomerId = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    update() {
      this.notes.push({
        note: "",
      });
    },
    removee(index) {
      this.notes.splice(index, 1);
    },
    stateList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/states",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.states = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteType() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/type/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtTypes = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteStock() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/stock/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtStock = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteOptions() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quotes/options/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtOptions = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    quoteQunatity() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/quote/quantity",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.qtQuantity = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addCustomer() {
      this.customer.phone = this.phoneno;
      axios({
        method: "POST",
        url: "/customer/add",
        data: this.customer,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            // this.reset();
            // this.resetValidation();
            this.cutmId = response.data.Cid;
            if (this.notes) this.addNotes();
            else {
              this.showsnackbar = true;
              this.msg = response.data.msg;
              this.$router.push("Customer/" + this.cutmId);
            }
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addNotes() {
      axios({
        method: "POST",
        url: "/note/add",
        data: {
          customerId: this.cutmId,
          notes: this.notes,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.$router.push("Customer/" + this.cutmId);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle {
  padding-bottom: 0px !important;
}
</style>